<template>
  <v-card flat class="xmax-w-[1200px] xmx-auto">
    <v-progress-linear
      indeterminate
      :height="10"
      class="my-1"
      v-if="fetching"
      color="primary"
    ></v-progress-linear>
    <v-card flat class="xw-full xshadow-lg xmin-h-screen">
      <v-alert
        dense
        type="warning"
        outlined
        v-if="!$can.and('manage_bot_responses')"
      >
        <div class="xflex xflex-col xjustify-center xitems-start">
          You dont have enough permission to add,update and remove bot responses
        </div>
      </v-alert>
      <v-sheet flat class="xmb-[1em] xpx-[1em]">
        <EachSetting
          title="Set Bot Response Source"
          :class="laptopUp ? 'xgrid-cols-2' : 'xgrid-cols-1'"
          class="xgrid"
          subtitle="You can choose which bot responses will be used for your team."
        >
          <template v-slot:left>
            <strong> Team and Bot </strong>
            <div
              class="xborder-l-4 xmy-1 xborder-[#2e2e2e] xp-2 xtext-[#2e2e2e] xfont-[400] xtext-[14px] xmax-w-[800px]"
            >
              By default, {{ appName }} uses both the responses from
              {{ appName }} and your specified team's bot responses randomly.
            </div>
            <strong> Team Only </strong>
            <div
              class="xborder-l-4 xmy-1 xborder-[#2e2e2e] xp-2 xtext-[#2e2e2e] xfont-[400] xtext-[14px] xmax-w-[800px]"
            >
              {{ appName }} will use responses from your team's customized bot. If the team has not set responses for a particular command, {{ appName }} will use its own bot responses.
            </div>
            <strong> Bot Only </strong>
            <div
              class="xborder-l-4 xmy-1 xborder-[#2e2e2e] xp-2 xtext-[#2e2e2e] xfont-[400] xtext-[14px] xmax-w-[800px]"
            >
              {{ appName }} will use responses from the app.
            </div>
          </template>
          <template v-slot:right>
            <v-autocomplete
              hide-details="auto"
              dense
              class="xmax-w-[300px] xmx-auto"
              :disabled="!$can.and('manage_bot_responses')"
              v-model="response_source"
              outlined
              @change="handleChange"
              :items="options"
              item-text="text"
              item-value="value"
            ></v-autocomplete>
          </template>
        </EachSetting>
      </v-sheet>
      <v-expansion-panels flat v-model="panel" multiple class="xpx-0">
        <EachBotType
          :title="item.title"
          :type="item.type"
          v-for="(item, i) in panels"
          :key="i"
        ></EachBotType>
      </v-expansion-panels>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EachBotType from "../components/EachBotType.vue";
import EachSetting from "../components/EachSetting.vue";
export default {
  components: { EachBotType, EachSetting },
  data() {
    return {
      tabs: [
        { to: { name: "app-settings-bot-responses" }, name: "Bot Responses" },
      ],
      panel: [0, 1, 2, 3],
      panels: [
        {
          title:
            "Enter the bot response when a user clocks <span class='clock-in'>/in</span>",
          type: "in",
        },
        {
          title:
            "Enter the bot response when a user clocks <span class='clock-brb'>/brb</span>",
          type: "brb",
        },
        {
          title:
            "Enter the bot response when a user clocks <span class='clock-back'>/back</span>",
          type: "back",
        },
        {
          title:
            "Enter the bot response when a user clocks <span class='clock-out'>/out</span>",
          type: "out",
        },
      ],
      options: [
        {
          text: "Team and Bot",
          value: "both",
        },
        { text: "Team Only", value: "team" },
        { text: "Bot Only", value: "app" },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("bots", ["fetching"]),
    ...mapGetters("workspaceconfigs", ["getByKey"]),
    response_source: {
      get() {
        return this.getByKey("response_source", "both");
      },
      set(val) {
        this.set_config({ key: "response_source", value: val });
      },
    },
  },
  created() {
    this.fetchBotResponses(this.user.team_id);
  },
  mounted() {
    this.fetchWorkspaceConfigs(this.user.team_id);
  },
  methods: {
    ...mapMutations("workspaceconfigs", ["set_config"]),
    ...mapActions("bots", ["fetchBotResponses"]),
    ...mapActions("workspaceconfigs", [
      "updateWorkspaceConfig",
      "fetchWorkspaceConfigs",
    ]),
    handleChange(val) {
      this.updateWorkspaceConfig({
        id: this.user.team_id,
        payload: { key: "response_source", value: val },
        cb: () => {
          this.appToast("Bot response source updated", "success");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
